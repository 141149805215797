import "./GroupButton.scss";

import ArrowRight from "../../../img/arrow-right.svg";
import { Link } from "gatsby";
import React from "react";

const GroupButton = ({ text, to, icon, target }) => {
  return (
    <>
      {target ? (
        <a href={to} className="GroupButton" target={target}>
          <span className="buttonLabel">{text}</span>
          <span className="button">
            <img src={icon ? icon : ArrowRight} alt="" />
          </span>
        </a>
      ) : (
        <Link to={to} className="GroupButton">
          <span className="buttonLabel">{text}</span>
          <span className="button">
            <img src={icon ? icon : ArrowRight} alt="" />
          </span>
        </Link>
      )}
    </>
  );
};

export default GroupButton;
