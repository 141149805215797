import Img from "gatsby-image";
import React from "react";

const PreviewCompatibleImage = ({ imageInfo, className, style }) => {
  const imageStyle = { ...style };
  const alt = !!imageInfo && !!imageInfo.alt ? !!imageInfo.alt : "";

  const imageFluid =
    !!imageInfo &&
    !!imageInfo.childImageSharp &&
    !!imageInfo.childImageSharp.fluid
      ? imageInfo.childImageSharp.fluid
      : false;

  const staticImage =
    !!imageInfo && !!imageInfo.image && typeof imageInfo.image === "string"
      ? imageInfo.image
      : false;

  const image =
    !!imageInfo && typeof imageInfo === "string" ? imageInfo : false;

  if (imageFluid) {
    return (
      <Img
        className={className}
        style={imageStyle}
        fluid={imageFluid}
        alt={alt}
      />
    );
  }

  if (staticImage) {
    return (
      <img
        className={className}
        style={imageStyle}
        src={staticImage}
        alt={alt}
      />
    );
  }

  if (image)
    return (
      <img
        className={className}
        style={imageStyle}
        src={`${process.env.GATSBY_HOST}${image}`}
        alt={alt}
      />
    );

  return null;
};

export default PreviewCompatibleImage;
